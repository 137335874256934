import { Box, Typography } from "@mui/material";
import Select from "components/Modules/Select";
import { Controller } from "react-hook-form";
import { useTranslation } from "next-i18next";

const SelectControlled = ({
  id,
  label,
  options,
  control,
  name,
  menuPlacement,
  isMulti,
  maxWidth,
  placeholder = "Select",
  mb = 3,
}) => {
  const { t } = useTranslation("filters");

  return (
    <Box zIndex={6}>
      <Typography fontWeight={600} mb={mb}>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <Select
            id={id}
            value={value}
            onChange={onChange}
            placeholder={t(placeholder)}
            options={options}
            menuPlacement={menuPlacement}
            isMulti={isMulti}
            maxWidth={maxWidth}
          />
        )}
      />
    </Box>
  );
};

export default SelectControlled;
