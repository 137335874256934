import { useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Box, Typography } from "@mui/material";
import cs from "classnames";

import theme from "theme";
import style from "./style.module.scss";
import Photo from "../../../assets/images/dom.jpg";

import BoxInformation from "./components/BoxInformation";
import BoxStatus from "./components/BoxStatus";

import useConditionalClick from "hooks/useConditionalClick";
import {
  convertNameOfCountFloor,
  convertNameOfCountRooms,
  convertPrice,
} from "utils/functions";

import {
  UnfoldMore,
  DashboardOutlined,
  Crop,
  ViewColumnOutlined,
  Yard,
  Balcony,
} from "@mui/icons-material";

import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";

const FavoritesButton = dynamic(
  () => import("components/Atoms/FavoritesButton"),
  { ssr: false }
);
const Item = ({
  uuid,
  price,
  area,
  rooms,
  floor,
  balcony,
  green_terrace,
  terrace,
  city_name,
  quarter_name,
  street_name,
  additionals,
  navigate,
  containerStyle,
  export_to_dommi,
  advert_title_on_dommi,
  image,
  contextMenuHandlers = {},
}) => {
  const [hoverItem, setHoverItem] = useState(false);

  const router = useRouter();
  const { t } = useTranslation();
  const { t: tProperty } = useTranslation("property");

  const handleClick = (e) => {
    if (e?.nativeEvent?.which === 1) {
      router.push(navigate);
    }
  };

  const conditionalClickEvent = useConditionalClick(handleClick);

  const getAdditionals = useMemo(() => {
    if (!additionals) return [];
    const { reservation, new: isNew, premium } = additionals;
    const arr = [];

    if (reservation) {
      arr.push({
        text: "Zarezerwowane",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.secondary,
      });
    }
    // if (toSell) {
    //   arr.push({
    //     text: "Na sprzedaż",
    //     backgroundColor: theme.palette.primary.main,
    //     color: theme.palette.text.secondary,
    //   });
    // }
    if (isNew) {
      arr.push({
        text: "Najnowsze",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      });
    }
    if (premium) {
      arr.push({
        text: "Premium",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
      });
    }
    return arr;
  }, [additionals]);

  const getCityAndQuarter = useMemo(() => {
    if (!city_name && !quarter_name) return null;
    const arr = [city_name, quarter_name];
    return arr.filter((item) => item).join(", ");
  }, [city_name, quarter_name]);

  return (
    <Box
      key={uuid}
      backgroundColor="white"
      p={4}
      height="100%"
      borderRadius={4}
      flex="1"
      onMouseOver={() => setHoverItem(true)}
      onMouseOut={() => setHoverItem(false)}
      style={containerStyle}
    >
      <Box
        width="100%"
        height={250}
        position="relative"
        borderRadius={4}
        overflow="hidden"
      >
        {navigate ? (
          <Link
            passHref
            href={navigate}
            {...conditionalClickEvent}
            {...contextMenuHandlers}
            style={{
              position: "relative",
              display: "block",
              height: "100%",
            }}
          >
            <Image
              alt="Mountains"
              src={image || Photo}
              className={cs(style.image, hoverItem ? style.imageHover : null)}
              fill={true}
              sizes="100%"
              style={{
                objectFit: "cover",
              }}
            />
          </Link>
        ) : (
          <Image
            alt="Mountains"
            src={image || Photo}
            objectFit="cover"
            className={cs(style.image, hoverItem ? style.imageHover : null)}
            fill={true}
            sizes="100%"
            style={{
              objectFit: "cover",
            }}
          />
        )}
        <Box position="absolute" top={0} left={0} padding={2}>
          {getAdditionals.map((el) => (
            <BoxStatus
              key={el?.text}
              backgroundColor={el?.backgroundColor}
              color={el?.color}
              text={el?.text}
              className={cs(style.status, hoverItem ? style.statusHover : null)}
            />
          ))}
        </Box>

        <Box
          position="absolute"
          top={0}
          right={0}
          margin={2}
          sx={{
            transition: "all .3s",
            ":hover": {
              transform: "scale(1.2)",
            },
          }}
        >
          <FavoritesButton uuid={uuid} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" py={4}>
        <Box>
          {export_to_dommi && advert_title_on_dommi ? (
            <Typography> {advert_title_on_dommi}</Typography>
          ) : null}
          {navigate ? (
            <Link
              passHref
              href={navigate}
              {...conditionalClickEvent}
              {...contextMenuHandlers}
            >
              <Typography fontWeight={600}>{getCityAndQuarter}</Typography>
            </Link>
          ) : (
            <Typography fontWeight={600}>{getCityAndQuarter}</Typography>
          )}
          {street_name ? <Typography>ul. {street_name}</Typography> : null}
        </Box>
        <Box textAlign="end">
          <Typography fontWeight={600}>
            {price ? `${convertPrice(price)} PLN` : "Brak ceny"}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        <BoxInformation icon={<Crop />}>
          {area} m<sup>2</sup>
        </BoxInformation>
        <BoxInformation icon={<DashboardOutlined />}>
          {rooms} {convertNameOfCountRooms(rooms)}
        </BoxInformation>
        <BoxInformation icon={<UnfoldMore />}>
          {convertNameOfCountFloor(floor)}
        </BoxInformation>
        {balcony === "1" ? (
          <BoxInformation
            icon={<ViewColumnOutlined />}
            sxText={{ textTransform: "capitalize" }}
          >
            {t("balcony")}
          </BoxInformation>
        ) : null}
        {green_terrace === "1" ? (
          <BoxInformation icon={<Yard />}>{tProperty("Garden")}</BoxInformation>
        ) : null}
        {terrace === "1" ? (
          <BoxInformation icon={<Balcony />}>
            {tProperty("Terrace")}
          </BoxInformation>
        ) : null}
      </Box>
    </Box>
  );
};

export default Item;
