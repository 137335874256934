import { Controller } from "react-hook-form";
import { ButtonGroup, Typography } from "@mui/material";
import Button from "components/Atoms/Button";
import theme from "theme";

const ButtonGroupControlled = ({ label, control, name, marks = [] }) => {
  const onClicked = (number, lastValues) => {
    let selectedRooms = [];

    if (lastValues.length === 0) {
      selectedRooms = [number, number];
    } else if (lastValues.length === 1) {
      if (number === lastValues[0]) {
        selectedRooms = [];
      } else {
        selectedRooms = [
          Math.min(number, lastValues[0]),
          Math.max(number, lastValues[0]),
        ];
      }
    } else if (lastValues.length === 2) {
      if (number === lastValues[0] || number === lastValues[1]) {
        if (number === lastValues[0] && number === lastValues[1]) {
          selectedRooms = [];
        } else if (number === lastValues[0]) {
          selectedRooms = [lastValues[1], lastValues[1]];
        } else if (number === lastValues[1]) {
          selectedRooms = [lastValues[0], lastValues[0]];
        }
      } else if (number < lastValues[0]) {
        selectedRooms = [number, lastValues[1]];
      } else if (number > lastValues[1]) {
        selectedRooms = [lastValues[0], number];
      }
    }

    return selectedRooms;
  };

  return (
    <>
      <Typography fontWeight={600}>{label}</Typography>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <ButtonGroup
              width="100%"
              variant="contained"
              aria-label="outlined primary button group"
            >
              {marks?.map((el) => {
                const variant = () => {
                  if (
                    value?.length !== 0 &&
                    parseFloat(el.value) >= value[0] &&
                    parseFloat(el.value) <= value[value?.length - 1]
                  ) {
                    return {
                      backgroundColor: "rgba(52, 64, 75, 1)",
                      color: "white",
                    };
                  }
                };
                return (
                  <Button
                    key={el.value}
                    onClick={() => {
                      const getNewValue = onClicked(el.value, value);
                      onChange(getNewValue);
                    }}
                    variant={"secondary"}
                    sx={{
                      ...variant(),
                      flexGrow: 1,
                      "&:hover": {
                        backgroundColor: {
                          xs: "rgba(52, 64, 75, 1)",
                          lg: "rgba(52, 64, 75, 0.6)",
                        },
                        color: "white",
                      },
                    }}
                  >
                    {el.label}
                  </Button>
                );
              })}
            </ButtonGroup>
          );
        }}
      />
    </>
  );
};

export default ButtonGroupControlled;
