import { forwardRef, useMemo } from "react";
import ReactSelect from "react-select";

import theme from "theme";

const Select = forwardRef(
  (
    {
      id,
      options = [],
      value,
      onChange = () => {},
      placeholder = "",
      menuPlacement = "bottom",
      isMulti = false,
      maxWidth,
      width,
      size = "medium",
      icon = null,
      input = null,
      styleOptions = {},
    },
    ref
  ) => {
    const sizeOptions = useMemo(() => {
      let padding = 10;

      switch (size) {
        case "small":
          padding = 6;
          break;

        case "medium":
          padding = 10;
          break;

        case "large":
          padding = 14;
          break;

        default:
          padding = 10;
          break;
      }

      return { padding };
    }, [size]);

    const components = useMemo(() => {
      const items = {};

      if (icon) {
        items.DropdownIndicator = icon;
      }
      if (input) {
        items.Input = input;
      }

      return items;
    }, [icon, input]);

    const getStyle = (base, state, nameState) => {
      if (state?.[nameState]) {
        return {
          ...base,
          ...state?.[nameState],
        };
      } else return base;
    };

    return (
      <div
        style={{
          maxWidth: maxWidth ? maxWidth : null,
          width: width ? width : null,
        }}
      >
        <ReactSelect
          ref={ref}
          id={id}
          instanceId={id}
          options={options}
          onChange={(val) =>
            isMulti ? onChange(val.map((c) => c.value)) : onChange(val.value)
          }
          value={
            isMulti
              ? options.filter((c) => {
                  return value?.includes(`${c.value}`);
                })
              : options.find((c) => c.value === value)
          }
          isMulti={isMulti}
          placeholder={placeholder}
          noOptionsMessage={() => "Brak opcji do wyboru"}
          styles={{
            control: (styles, state) => ({
              ...styles,
              overflow: "hidden",
              backgroundColor: "transparent",
              boxShadow: state.isFocused ? "0 0 6px 1px #cae6ef" : "none",
              borderRadius: 8,
              borderColor: "#ddeff5",
              transition: "all 0.2s ease-in-out",
              cursor: "pointer",
              ":hover": {
                borderColor: "#cae6ef",
                boxShadow: "0 0 6px 1px #cae6ef",
              },
            }),

            valueContainer: (styles) => ({
              ...styles,
              backgroundColor: "white",
              padding: sizeOptions.padding,
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: "none",
            }),

            multiValue: (styles) => ({
              ...styles,
              backgroundColor: "#F6FAFF",
              border: "1px solid #DDEFF5",
              borderRadius: 6,
              padding: 4,
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              borderRadius: 6,
              ":hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.tertiary.contrastText,
              },
            }),
            indicatorsContainer: (styles, props) => {
              return {
                ...styles,
                marginRight: styleOptions?.dropdownIndicator ? 0 : 10,
                marginLeft: styleOptions?.dropdownIndicator ? 0 : 10,
                gap: 2,
              };
            },
            clearIndicator: (styles) => ({
              ...styles,
              cursor: "pointer",
              transition: "all 0.2s ease-in-out",
              borderRadius: 6,
              padding: 4,
              ":hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.tertiary.contrastText,
              },
            }),
            dropdownIndicator: (styles) => ({
              ...styles,
              ...getStyle(
                {
                  cursor: "pointer",
                  transition: "all 0.2s ease-in-out",
                  borderRadius: 6,

                  padding: 4,
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.tertiary.contrastText,
                  },
                },
                { ...styleOptions },
                "dropdownIndicator"
              ),
            }),
          }}
          menuPlacement={menuPlacement}
          components={components}
        />
      </div>
    );
  }
);

export default Select;
