export const defaultValues = {
  location: null,
  area: ["", ""],
  kitchenType: null,
  rooms: [],
  floor: [],
  price: [0, 10000],
  available_from: null,
  equipments: null,
};

export const marksButtonGroup = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 99,
    label: "5+",
  },
];
