import { Box, Typography } from "@mui/material";

const BoxStatus = ({ text, backgroundColor, color, className }) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      px={2}
      py={1}
      borderRadius={2}
      mb={2}
      width="max-content"
      color={color}
      className={className}
    >
      <Typography color="white" fontSize="0.8rem" fontWeight={600}>
        {text}
      </Typography>
    </Box>
  );
};

export default BoxStatus;
