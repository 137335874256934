import { isArray, isString } from "../functions";
import { defaultValues } from "../defaults";
import {
  checkValues,
  convertDateToDayJS,
  convertStringToNumber,
  createObjectFromArray,
  findInArray,
} from "./utils";

export const convertToApi = (values) => {
  const {
    location,
    area,
    kitchenType,
    rooms,
    floor,
    price,
    available_from,
    equipments,
  } = values;

  const data = {
    investment_uuid: isArray(location) ? location : null,
    area_from: isArray(area) && area.length > 0 ? area[0] : null,
    area_to: isArray(area) && area.length > 1 ? area[1] : null,
    annex: findInArray("annex", kitchenType),
    kitchen: findInArray("kitchen", kitchenType),
    rooms_from:
      isArray(rooms) && rooms.length > 0
        ? rooms[0] === 99
          ? 5
          : rooms[0]
        : null,
    rooms_to: isArray(rooms) && rooms.length > 1 ? rooms[1] : null,
    floor_from:
      isArray(floor) && floor.length > 0
        ? floor[0] === 99
          ? 5
          : floor[0]
        : null,
    floor_to: isArray(floor) && floor.length > 1 ? floor[1] : null,
    price_from: isArray(price) && price.length > 0 ? price[0] : null,
    price_to: isArray(price) && price.length > 1 ? price[1] : null,
    available_from: available_from,
    equipments: isArray(equipments) ? equipments : null,
  };

  const clearEmptyData = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") clearEmptyData(obj[key]);
      else if (obj[key] === null || obj[key] === "") delete obj[key];
      else if (typeof obj[key] === "number" && isNaN(obj[key])) delete obj[key];
    });

    return obj;
  };

  return clearEmptyData(data);
};

export const convertFromApi = (data) => {
  const dataLength = Object.keys(data)?.length;
  if (!data || dataLength === 0) return defaultValues;

  const {
    investment_uuid,
    area_from,
    area_to,
    kitchen,
    annex,
    rooms_from,
    rooms_to,
    floor_from,
    floor_to,
    price_from,
    price_to,
    available_from,
    equipments,
  } = data;

  const values = {
    location: isArray(investment_uuid)
      ? investment_uuid
      : isString(investment_uuid)
      ? [investment_uuid]
      : null,

    area: checkValues(area_from, area_to, "0", true),
    kitchenType: createObjectFromArray({ kitchen, annex }),
    rooms: checkValues(rooms_from, rooms_to),
    floor: checkValues(floor_from, floor_to),
    price: [
      convertStringToNumber(price_from) || defaultValues.price[0],
      convertStringToNumber(price_to) || defaultValues.price[1],
    ],
    available_from: available_from ? convertDateToDayJS(available_from) : null,
    equipments: isArray(equipments)
      ? equipments
      : isString(equipments)
      ? [equipments]
      : null,
  };

  return values;
};
