import { useRef } from "react";

const defaultOptions = {
  shouldPreventDefault: true,
  threshold: 10,
};

const useConditionalClick = (onClick, options = {}) => {
  const mergedOptions = { ...defaultOptions, ...options };
  const startPos = useRef({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    startPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleMouseUp = (e) => {
    const endPos = { x: e.clientX, y: e.clientY };
    const diffX = Math.abs(startPos.current.x - endPos.x);
    const diffY = Math.abs(startPos.current.y - endPos.y);

    if (diffX < mergedOptions.threshold && diffY < mergedOptions.threshold) {
      onClick(e);
    }
  };

  return {
    onClick: (e) => {
      if (mergedOptions.shouldPreventDefault) {
        e.preventDefault();
      }
    },
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
  };
};

export default useConditionalClick;
