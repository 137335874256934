import dayjs from "dayjs";
import { isArray } from "utils/functions";

export const findInArray = (value, list) => {
  if (!list || !isArray(list)) return null;
  const item = list?.find((item) => item === value);
  return item ? "1" : null;
};

export const checkValues = (
  from,
  to,
  defaultFirstValue = null,
  emptyToValue = false
) => {
  let result = [];

  if (from === undefined && to === undefined) {
    return result;
  }

  if (from === undefined || from === null || from === "") {
    result.push(defaultFirstValue || to, to);
  } else if (to === undefined || to === null || to === "") {
    result.push(from, emptyToValue ? 0 : from);
  } else {
    result.push(from, to);
  }

  return result;
};

export const getName = (value, list = []) => {
  if (!list || !isArray(list)) return null;
  const item = list.find((item) => item.value === value);
  return item ? item.label : null;
};

export const convertStringToNumber = (value) => {
  if (value === undefined || value === null || value === "") {
    return null;
  }

  return Number(value);
};

export const createObjectFromArray = (obj) => {
  const result = [];

  for (const [key, value] of Object.entries(obj)) {
    if (value === "1" || value === 1) {
      result.push(key);
    }
  }

  return result?.length > 0 ? result : null;
};

export const convertDateToDayJS = (v, format = "YYYY-MM-DD") => {
  if (!v) return null;

  const vNew = dayjs(v, format);

  const y = dayjs(vNew).year();
  const m = dayjs(vNew).month();
  const d = dayjs(vNew).date();

  return dayjs().set("year", y).set("month", m).set("date", d);
};
